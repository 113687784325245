.ItemPreview {
  //flex sum≈1080 (as width of design)
  margin-top: 40px;
  @media (max-width: 575px) {
    //mobile
    margin-top: 20px;
  }
  .images-carousal {
    width: 100%;
    .slick-initialized .slick-slide {
      padding-inline: 10px;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
  .item {
    display: flex;
    @media (max-width: 575px) {
      //mobile
      flex-direction: column;
    }
    > *:not(:last-child) {
      margin-right: 40px;
      @media (max-width: 575px) {
        //mobile
        margin-right: 0px;
        margin-bottom: 37px;
      }
    }
    .title {
      font-size: 32px;
      line-height: 32px;
      @media (max-width: 575px) {
        //mobile
        font-size: 22px;
      }
    }
    > .title {
      @media (min-width: 576px) {
        display: none;
      }
    }
    .image {
      flex: 300;
      max-width: 280px;
      img {
        width: 47%;
        cursor: pointer;
        max-height: 80px;
        float: right;
        object-fit: contain;
        margin-bottom: 15px;
      }
      img:first-of-type {
        width: 100%;
        max-height: inherit;
      }

      img:nth-child(2n) {
        float: left;
      }
      

    }

    @media (max-width: 575px) {
      .image {
        margin-inline: auto;
      }
    }
    .desc {
      > *:not(:last-child) {
        margin-bottom: 25px;
      }
      flex: 710;
      display: flex;
      flex-direction: column;
      > .title {
        @media (max-width: 575px) {
          //mobile
          display: none;
        }
      }
      .not-title {
        > *:not(:last-child) {
          margin-right: 40px;
          @media (max-width: 575px) {
            //mobile
            margin-right: 0px;
            margin-bottom: 16px;
          }
        }
        @media (max-width: 575px) {
          //mobile
          flex-direction: column;
        }
        display: flex;
        .description-text-container {
          flex: 410;
          display: flex;
          flex-direction: column;
          > *:not(:last-child) {
            margin-bottom: 22px;
            @media (max-width: 575px) {
              //mobile
              margin-bottom: 24px;
            }
          }
          .small-description {
            &:last-of-type {
              @media (max-width: 575px) {
                //mobile
                margin-bottom: 24px;
              }
            }
          }
          .description-text {
            white-space: pre-wrap;
          }
          > .item-filters {
            @media (min-width: 576px) {
              display: none;
            }
            margin-top: 11px;
            margin-bottom: 16px;
            > .item-filter {
              button {
                width: auto;
              }
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }
        }
        .misc {
          flex: 300;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          > *:not(:last-child) {
            margin-bottom: 22px;
            @media (max-width: 575px) {
              //mobile
              margin-bottom: 37px;
            }
            &.item-filter {
              margin-bottom: 8px;
            }
          }
          .item-filters {
            @media (max-width: 575px) {
              //mobile
              display: none;
            }
          }
          .links > a {
            &:not(:last-child) {
              margin-bottom: 12px !important;
              display: block;
            }
          }
        }
      }
    }
  }
  .related-books-container {
    margin-top: 50px;
    .related-books-title {
      margin: 0px 0px 30px 0px;
      font-size: 25px;
      @media (max-width: 575px) {
        //mobile
        font-size: 18px;
      }
    }
    .all-related-books-btn {
    width: 100%;
    border-radius: 18px;
    height: 50px;
    text-transform: uppercase;
    margin-top: 20px;
    }
  }
}
