.Header {
  $header-height: 96px;
  $mobile-header-height: 64px;
  .headroom-wrapper {
    width: 100%;
    max-width: $max-content-width;
    margin: 0px auto;
    background-color: $white;

    position: relative;
    z-index: 50;
  }
  .headroom {
    width: 100%;
    max-width: $max-content-width;
    margin: 0px auto;
    will-change: transform;

    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &--unfixed {
      position: relative;
      transform: translateY(0);
    }
    &--scrolled {
      transition: transform 200ms ease-in-out;
    }
    &--unpinned {
      position: fixed;
      transform: translateY(-100%);
    }
    &--pinned {
      position: fixed;
      transform: translateY(0%);
    }
  }
  &.mobileMenuIsOpen,
  &.searchIsOpen {
    .headroom {
      position: fixed;
      transform: translateY(0%);
    }
  }
  .header-container {
    width: 100%;
    max-width: $max-content-width;
    height: $mobile-header-height;
    margin: 0 auto;
    padding: 0 var(--horizontal-padding);
    @include breakpoint(md) {
      height: $header-height;
      padding: 0 var(--horizontal-padding);
    }
    background-color: #fff;
    display: flex;
    align-items: center;
  }
  // .logo-img-container {
  //   margin-right: 49px;
  // }
  .logo-link {
    @include outline(0);
    .logo-text {
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .header-right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .nav-container {
    display: none;
    @include breakpoint(xl) {
      display: block;
    }
  }
  .search-container {
    @media (max-width: 767px) {
      //mobile
      flex-direction: row-reverse;
      .nav-list {
        padding:0 10px;
      }
    }
  }
  .nav {
    font-size: 22px;
  }
  .nav-list {
    margin: 0;
    padding: 0 10px 0 50px;
    list-style-type: none;
    display: flex;
  }
  .nav-item {
    &:not(:last-child) {
      padding-right: 24px;
    }
  }

  .cart-container {
    display: flex;
    align-items: center;
    margin-right: 25px;
    @include breakpoint(sm-down) {
      margin-right: 14px;
    }
  }
  .cart-icon {
    display: block;
    margin-right: 10px;
  }
  .cart-number {
    font-family: $global-font-family;
    font-size: 24px;
    color: #131413;
  }
  .user-container {
    @include breakpoint(lg-down) {
      display: none;
    }
  }
  .login-link {
    @include outline(0);
    // cursor: pointer;
    // border: none;
    // background: none;
    // padding: 0;
    font-size: 24px;
    color: #131413;
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .hamburger-container {
    width: 48px;
    height: 48px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(xl) {
      display: none;
    }
  }
  .mobile-nav-container {
    display: none;
    position: fixed;
    top: $mobile-header-height;
    max-height: calc(100vh - #{$mobile-header-height});
    @include breakpoint(md) {
      top: $header-height;
      max-height: calc(100vh - #{$header-height});
    }
    left: 0;
    width: 100%;
    height: 100%;
    // top: 0;
    // padding: calc( #{$header-height} + 20px ) var(--horizontal-padding) 20px;
    // max-height: 100vh;
    padding: 20px var(--horizontal-padding) 20px;
    z-index: 40;

    overflow-y: auto;

    //padding: 20px var(--horizontal-padding) 20px;
    background-color: $white;
  }
  &.mobileMenuIsOpen {
    .mobile-nav-container {
      @include breakpoint(lg-down) {
        display: block;
      }
    }
  }
  .mobile-nav {
    // width: 100%;
    // height: 100%;
    //display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .mobile-nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
  .mobile-nav-item {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
  .mobile-nav-link {
    @include outline(0);
    font-size: 26px;
  }
  &.alwaysShow {
    .headroom {
      position: fixed;
    }
  }

  @media (max-width: 575px) {
    
    .header-content {
      margin-left: 16px;

      a:focus, a:active {
        color: #DD1612;
        text-decoration: none;
      }
    }
  }
}
