footer {
  .about-site,
  .about-site * {
    color: #272727;
  }
  .catalog-website-line {
    margin-bottom: 50px;
    justify-content: space-between;
    @media (max-width: 575px) {
      // mobile
      margin-bottom: 24px;
      flex-direction: column;
      > *:not(:last-child) {
        margin-bottom: 24px;
      }
      .link-with-icon {
        .icon-at-end {
          display: none;
        }
        .icon-at-start {
          display: block !important;
        }
        span {
        }
      }
    }
    .link-with-icon {
      span {
        white-space: nowrap;
      }
      .icon-at-start {
        display: none;
      }
    }
    .logo-text {
      @media (max-width: 575px) {
        // mobile
        font-size: 16px;
      }
    }
  }
}
