.App {
  margin: 0 auto;
  max-width: $max-content-width;
  min-height: 100vh;

  .main {
    // min-height: calc(100vh - 270px);
    min-height: calc(100vh);
    padding: 0 var(--horizontal-padding) 0;
    @include breakpoint(md) {
      padding: 0 var(--horizontal-padding) 0;
    }
    background-color: $white;
  }
}

.not-display-on-mobile {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}

.link-with-icon {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 15px;
  line-height: 18px;
}

.hide-on-mobile {
  @media (max-width: 575px) {
    // mobile
    display: none !important;
  }
}
.hide-on-not-mobile {
  @media (min-width: 575px) {
    // mobile
    display: none !important;
  }
}
.hide-on-767 {
  @media (max-width: 767px) {
    // mobile
    display: none !important;
  }
}
