.item-filter {
  div {
    display: flex;
    // max-width: 100%;
    flex-wrap: wrap;
    &.selected-filter button {
      color: #dd1612;
      border: 1px solid #dd1612;
      &:focus,
      &:hover,
      &:active {
        color: white;
        border: 1px solid black;
        svg {
          fill: black !important;
        }
      }
    }
    .filter-option-container {
      margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: 8px;
      }
      button {
        border-radius: 10px;
        padding: 0;
        height: unset !important;
        min-height: unset !important;
        height: 24px;
        flex: 0 0 auto;
        padding: 6px 15px;

        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .filter-button-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .filter-left-icon {
        margin-right: 10px;
      }
      .filter-right-icon {
        margin-left: 10px;
      }
    }
  }
  &.big {
    div .filter-option-container {
      &:not(:last-child) {
        margin-right: 16px;
      }
      margin-bottom: 16px;
      button {
        font-size: 18px !important;
        border-radius: 15px;
        padding: 12px 16px !important;
      }
    }
  }
}
