.slider-container, .slider-container2 {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  top: 0px;
  left: 0px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;

  h2 {
    font-size: 32px;
    margin-top: 32px;
  }

  .cross {
    position: absolute;
    right: 2px;
    top: 39px;
    cursor: pointer;
    svg {
      width: 100%;
    }
  }

  p {
    margin-block: 30px;
  }
  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 200px);
    .image-container {
      width: 90%;
      height: 100%;   
      position: relative !important; 

      div {
        height: 100%;
      }
    }
    .image,.image2 {
      object-fit: contain;
      max-height: 100%;
      width: 100%;
      -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 1s; /* Firefox < 16 */
       -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
           animation: fadein 1s;
    }
    
    .navButtons {
      width: 65px;
      height: 65px;
      cursor: pointer;
      z-index: 999;
    }
  }
}
.slider-container2 {
  -webkit-animation: fadeout 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 0.8s; /* Firefox < 16 */
   -ms-animation: fadeout 0.8s; /* Internet Explorer */
    -o-animation: fadeout 0.8s; /* Opera < 12.1 */
       animation: fadeout 0.8s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
