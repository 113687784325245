/*=========================================================
  01. #GLOBAL
=========================================================*/
html {
  box-sizing: border-box;
  line-height: 17px;
  letter-spacing: 0.2px;
}

*, *:before, *:after {
  box-sizing: inherit;
}
body{  
  font-family: $global-font-family;
  font-weight: 700;
  font-style: normal;
  color: $text-color;  
  background-color: $body-background-color;    
}
body * {
  font-family: $global-font-family; 
}

p{
  margin: 0 0 1em;
  &:last-child{
    margin: 0;
  }
}

a {
  cursor:pointer;
  user-select: none;
  text-decoration: none;
  color: $text-color;
  &:hover{
    color: $link-hover-color;
  }
  webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button{
  webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
h1,h2,h3,h4{
  margin: 0 0 1em;
  word-break: break-word;
}
