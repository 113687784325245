.Exhibitions {
  overflow: hidden;
  h1 {
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .ItemContainer {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-img {
      max-height: 505px;
      max-width: 68%;
      object-fit: contain;
    }

    h1:first-of-type {
      font-size: 52px;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      font-size: 20px;
      margin: 0px;
    }

    .content {
      margin-top: 40px;
      width: 100%;
    }

    .desc-toggle {
      margin-top: 40px;
      border-bottom: 1px solid #000;
      padding: 0px;
      cursor: pointer;
      width: fit-content;
      margin-inline: auto;
    }

    .subItemsContainer {
      h3 {
        margin-block: 50px;
        font-size: 22px;
      }

      width: 100%;
    }

    @media (max-width: 575px) {

      
        .main-img {
          width: 100%;
          max-width: 100%;
          max-height: 216px;
          object-fit: cover;
        }
  
        h1:first-of-type {
          font-size: 22px;
        }
        p {
          color: #131413;
        }
        .subItemsContainer {
          h3 {
            font-size: 18px;
          }
        }
        .desc-toggle {
          margin-inline: 0px;
        }
    }
  }

}
